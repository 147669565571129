import type { GetStaticProps } from 'next'
import Image from 'next/legacy/image'
import ImageBanner from '@components/ImageBanner'
import type { pageTitle, pageContent } from '@lib/types'
import { CarouselBasic, CarouselThreeD, CarouselCabinContent } from '@components/Carousels'
import { getPageTitleContentFromDB, getPageContentFromDB } from '@lib/pagequerys'
import warriorLogo from '@public/images/warrior_bar_logo.png'
import { Container, Heading, List, Section, Text, LinkComponent, Spacer } from '@components/SectionComponents'

// Gets static props from DB
export const getStaticProps: GetStaticProps = async () => {

  // page_id in DB
  const pageIdDB: number = 2;

  // query DB for page title data
  const pageTitle: pageTitle = await getPageTitleContentFromDB(pageIdDB);

  // Array of pageContent - query DB for pageContent data
  const pageContent: pageContent[] = await getPageContentFromDB(pageIdDB);

  return {
    props: {
      pageTitle,
      pageContent
    }
  }
}

// Home Page takes a pageTitle, and pageContent array
export default function Page({ pageTitle, pageContent }: {
  pageTitle: pageTitle,
  pageContent: pageContent[],
}) {
  return <>

    <ImageBanner title={pageTitle} isHome={true} subColor="brown" />

    {/* Section 0 */}
    <Section colour='brown'>
      <Container longer='bottom'>
        <Heading h1={true} content={{ title: pageContent[0].title }} />
        <Text content={pageContent[0].text} />
        <LinkComponent link1={pageContent[0].link1} />
      </Container>
      <CarouselThreeD images={pageContent[0].carouselImages} overlap='bottom' />
    </Section>

    {/* Section 1 */}
    <Section colour='white'>
      <Container longer='both'>
        <Heading content={{ title: pageContent[1].title }} />
        <Text content={pageContent[1].text} />
        <List content={pageContent[1].list} />
        <Spacer />
        <LinkComponent link1={pageContent[1].link1} />
      </Container>

    </Section>

    {/* Section 2, 7 9, 11 */}
    <Section colour='cream'>

      <CarouselCabinContent overlap='top' images={[pageContent[7].carouselImages[0], pageContent[2].carouselImages[0], pageContent[9].carouselImages[0],pageContent[11].carouselImages[0]]}>
        
          <Container longer="both">
            <Heading content={{
              title: pageContent[7].title,
              subtitle: pageContent[7].subtitle
            }} />
            <Text content={pageContent[7].text} />
            <LinkComponent link1={pageContent[7].link1} link2={pageContent[7].link2} />
          </Container>
        
          <Container longer="both">
            <Heading content={{
              title: pageContent[2].title,
              subtitle: pageContent[2].subtitle
            }} />
            <Text content={pageContent[2].text} />
            <LinkComponent link1={pageContent[2].link1} link2={pageContent[2].link2} />
          </Container>
       
          <Container longer="both">
            <Heading content={{
              title: pageContent[9].title,
              subtitle: pageContent[9].subtitle
            }} />
            <Text content={pageContent[9].text} />
            <LinkComponent link1={pageContent[9].link1} link2={pageContent[9].link2} />
          </Container>

          <Container longer="both">
            <Heading content={{
              title: pageContent[11].title,
              subtitle: pageContent[11].subtitle
            }} />
            <Text content={pageContent[11].text} />
            <LinkComponent link1={pageContent[11].link1} link2={pageContent[11].link2} />
          </Container>
        
      </CarouselCabinContent>

    </Section>

    {/* Section 6 */}
    <Section colour='white'>
      <CarouselBasic images={pageContent[6].carouselImages} overlap='top' />
      <Container longer='top'>
        <Heading content={{ title: pageContent[6].title }} />
        <Text content={pageContent[6].text} />
        <LinkComponent link1={pageContent[6].link1} />
      </Container>
    </Section>

    {/* Section 3 */}
    <Section colour='dark'>
      <Container longer='bottom'>
        <Image
          src={warriorLogo}
          width={400}
          height={80}
          alt="Warrior Restaurant and Bar Logo"
          placeholder='blur'
          quality={100}
          style={{
            objectFit: "contain",
            width: '100%',
            maxWidth: 'fit-content',
            height: 'fit-content'
          }}
        />
      </Container>

      <CarouselThreeD images={pageContent[3].carouselImages} />
      <Container longer='bottom'>
        <Heading content={{ title: pageContent[3].title }} />
        <Text content={pageContent[3].text} />
        <LinkComponent
          link1={pageContent[3].link1}
          link2={pageContent[3].link2}
        />
      </Container>
    </Section>

    {/* Section 8 */}
    <Section colour='cream'>
      <CarouselBasic images={pageContent[8].carouselImages} overlap='top' />
      <Container longer="both">
        <Heading content={{
          title: pageContent[8].title,
          subtitle: pageContent[8].subtitle
        }} />
        <Text content={pageContent[8].text} />
        <LinkComponent
          link1={pageContent[8].link1}
          link2={pageContent[8].link2}
        />
      </Container>
    </Section>

    {/* Section 10 */}
    <Section colour='white'>
      <CarouselThreeD images={pageContent[10].carouselImages} overlap='top' />
      <Container longer="top">
        <Heading content={{ title: pageContent[10].title }} />
        <Text content={pageContent[10].text} />
        <LinkComponent link1={pageContent[10].link1} />
      </Container>
    </Section>

    {/* Section 4 */}
    <Section colour='cream'>
      <Container longer='bottom'>
        <Heading content={{ title: pageContent[4].title }} />
        <Text content={pageContent[4].text} />
      </Container>
    </Section>

    {/* Section 5 */}
    <Section colour='white'>
      <CarouselBasic images={pageContent[5].carouselImages} overlap='top' />
      <Container longer="both">
        <Heading content={{ title: pageContent[5].title }} />
        <Text content={pageContent[5].text} />
        <LinkComponent link1={pageContent[5].link1} />
      </Container>
    </Section>



  </>
}

//Meta Data
Page.title = "Home";
Page.description = "A beautiful hideaway nestled into the Australian bush near the famed Glass House Mountains and Sunshine Coast beaches, inspired by Steve’s life, legacy and love for wild places.";